import axios from '@/libs/axios.js'

let accessToken = localStorage.getItem('accessToken');

export default {
  save ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path + '/save', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          if(payloads.path === '/securite/user') {
            commit('ADD_ITEM_USER', Object.assign(response.data.data, {slug: response.data.data.slug}));
          } else {
            commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
          }
          
          resolve(response.data.data)
        } else {
          console.log('rejected from then')
          reject(response.data)
        }
      }).catch((error) => {
        console.log('rejected from catch')
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  importItem ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path + '/import', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        //commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
        resolve(response.data.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetch ({ commit }, paths) {
    return new Promise((resolve, reject) => {
      axios.get(paths + '/select', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('SET_LIST', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('SET_LIST', []);
          reject([]) 
        })
    })
  },
  fetchUser ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('securite/user/select', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if(response.status === 200) {
            commit('SET_USER_LIST', response.data.data);
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
          commit('SET_USER_LIST', []);
          reject([]) 
        })
    })
  },
  edit ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.put(payloads.path + '/edit', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          commit('UPDATE_ITEM', response.data.data);
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  editUser ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.put(payloads.path + '/edit', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          commit('UPDATE_ITEM_USER', response.data.data);

          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  remove ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.delete(payloads.path + '/remove/' + payloads.item, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(payloads.path === '/securite/user') {
          commit('REMOVE_ASTREINTE_USER', payloads.item);
        } else {
          commit('REMOVE_ITEM', payloads.item);
        }
        
        resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetchItem (context, payloads) {
    return new Promise((resolve, reject) => {
      axios.get(`/${payloads.path}/select/${payloads.slug}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if(response.status === 200) {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFile({ commit }, paths) {
    return new Promise((resolve, reject) => {
      axios.get(`${paths}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          commit('SET_FILE', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          commit('SET_FILE', '')
          reject(error)
        })
    })
  },
}
