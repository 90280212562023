export default {
  ADD_ITEM (state, item) {
    state.data.unshift(item)
  },
  ADD_ITEM_USER (state, item) {
    state.user.unshift(item)
  },
  SET_LIST (state, payload) {
    state.data = payload
  },
  SET_USER_LIST (state, payload) {
    state.user = payload
  },
  UPDATE_ITEM (state, payload) {
    const dataIndex = state.data.findIndex((p) => p.slug === payload.slug);
    Object.assign(state.data[dataIndex], payload)
  },
  UPDATE_ITEM_USER (state, payload) {
    const dataIndex = state.user.findIndex((p) => p.slug === payload.slug);
    Object.assign(state.user[dataIndex], payload)
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.data.findIndex((p) => p.slug === itemId);
    state.data.splice(ItemIndex, 1)
  },
  REMOVE_ASTREINTE_USER (state, itemId) {
    const ItemIndex = state.user.findIndex((p) => p.slug === itemId);
    state.user.splice(ItemIndex, 1)
  },
  // STRANGER MUTATIONS
  SET_FILE(state, payload) {
    console.log('GATHERING FILE...')
    state.file = payload
  },
}
